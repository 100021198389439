import { Typography, Box } from "@material-ui/core";
import requestIcon from '../../../assets/shiprequest.png';
import { LightTooltip } from "../../../Common/constant";
import { useNavigate } from "react-router-dom";

export default function CustomerShipRequest() {
    const navigate = useNavigate();

    const handleRedirect = () => navigate('/shipping-request');
    const getRedirectTooltip = 'Click here to open'

    return (
        <>
            <Box>
                <Typography align="left" className="reportTitle">
                    <span style={{ cursor: 'pointer' }} onClick={handleRedirect}>
                        Shipping Request
                    </span>
                </Typography>
                <LightTooltip title={getRedirectTooltip} onClick={() => handleRedirect()}>
                    <img src={requestIcon} alt="search-Icon" width={'25%'} style={{ marginLeft: '36%', marginTop: '11%', cursor: 'pointer' }} />
                </LightTooltip>
            </Box>
        </>
    )
}