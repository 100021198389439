import React, { useState, forwardRef, useEffect } from 'react';
import { TextField, Grid, Paper, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import { saveShipmentRequest, fetchShipReqByReqId, updateShippingReq, sendmail } from '../Common/API';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/datepicker.styles.css';
import calenderIcon from '../assets/calendarIcon.svg';
import { useParams } from 'react-router-dom';
import { useSnackbar } from "react-simple-snackbar";
import { OPTIONS, OPTIONS3 } from "../Common/constant";
import { jsPDF } from 'jspdf';
import "jspdf-autotable";
import { Autocomplete } from '@material-ui/lab';

function ShippingRequest() {
    const [info, setInfo] = useState({
        user_id: 0,
        r_name: '',
        r_email: '',
        r_phone: '',
        r_company_name: '',
        p_address: '',
        p_name: '',
        p_email: '',
        p_date: '',
        p_telephone: '',
        d_address: '',
        d_name: '',
        d_email: '',
        d_telephone: '',
        comments: '',
        mode_of_transport: '',
        created_by: 0,
        updated_by: 0,
    });

    const dangerousGoods = [{ id: 1, name: "Dangerous Goods" },
    { id: 2, name: "General Cargo" }]

    const stackable = [{ id: 'Y', name: "Yes" },
    { id: 'N', name: "No" }]

    const temperaturecontrol = [{ id: 'Y', name: "Yes" },
    { id: 'N', name: "No" }]

    // State to store table rows data
    const [rows, setRows] = useState([
        { clientName: '', poto: '', sku: '', skuDesc: '', units: '', cartons: '', pallets: '', dimperpallet: '', weight: '' }
    ]);
    const imagePath = `url(${calenderIcon})`;
    const [successSnackbar] = useSnackbar(OPTIONS);
    const [amberSnackbar] = useSnackbar(OPTIONS3);
    const { id } = useParams();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (id != null || id != undefined) {  // Check if id is neither null nor undefined
            getShipDataByReqId();
        }
    }, [id])

    const DateCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <TextField value={value}
            fullWidth
            label="Pickup Date"
            style={{
                background: `${imagePath} 260px/ 12px no-repeat`
            }}
            InputLabelProps={{ shrink: true }}
            onClick={onClick}
            onChange={onChange}
            ref={ref} placeholder="yyyy-mm-dd" />
    ));

    // State to store total weight
    const [totalWeight, setTotalWeight] = useState(0);

    // Function to handle input change in table
    const handleRowChange = (index, event) => {
        const { name, value } = event.target;
        const updatedRows = [...rows];
        updatedRows[index][name] = value;

        setRows(updatedRows);

        if (name === 'weight') {
            calculateTotalWeight(updatedRows);
        }
    };

    // Function to handle date change for pickup date
    const handlePickupDateChange = (date) => {
        const formattedDate = date.toISOString().split('T')[0]; // Format the date to 'yyyy-MM-dd'
        setInfo({
            ...info,
            p_date: formattedDate
        });
    };

    // Function to calculate total weight
    const calculateTotalWeight = (updatedRows) => {
        const totalWeight = updatedRows.reduce(
            (sum, row) => sum + (parseFloat(row.weight) || 0), 0
        );

        setTotalWeight(totalWeight);
    };

    // Function to add a new row
    const addRow = () => {
        setRows([...rows, { clientName: '', poto: '', sku: '', skuDesc: '', units: '', cartons: '', pallets: '', dimperpallet: '', weight: '' }]);
    };

    const validateField = (name, value) => {
        let error = '';
        if (!value) {
            error = 'This field is required';
        }
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInfo({
            ...info,
            [name]: value,
        });
        validateField(name, value);
    };

    const getShipDataByReqId = () => {
        (async () => {
            await fetchShipReqByReqId(id).then(data => {
                setInfo(data.info);
                setRows(data.rows);
            }).catch(error => {
                alert(error);
            })
        })();
    }

    const handleSubmit = () => {
        const newErrors = {};

        // Check only the required fields
        ['r_name', 'r_phone', 'r_email', 'r_company_name'].forEach((field) => {
            if (!info[field]) {
                newErrors[field] = 'This field is required';
            }
        });

        // If there are errors, set them and stop the form submission
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        // Prepare data for submission
        const dataToSubmit = {
            info: { ...info },
            rows,
        };

        // Set user_id and created_by based on localStorage value
        const userId = localStorage.getItem("userId");
        dataToSubmit.info.user_id = userId ? undefined : 0;
        dataToSubmit.info.created_by = userId ? undefined : 0;

        // Proceed with form submission
        try {
            saveShipmentRequest(dataToSubmit)
                .then((res) => {
                    if (res.status === 1) {
                        successSnackbar(res.message);
                        pdfToBlob(res.requestId);
                    } else {
                        amberSnackbar(res.message);
                    }
                })
                .catch((error) => {
                    amberSnackbar('An error occurred while saving the shipment request.');
                });
        } catch (error) {
            amberSnackbar('Submission Error: Please try again later.');
        }
    };


    const handleUpdate = () => {
        let updatedInfo = { ...info };

        // Exclude unwanted fields when updating
        if (id) {
            delete updatedInfo.r_name;
            delete updatedInfo.r_phone;
            delete updatedInfo.r_email;
            delete updatedInfo.r_company_name;
        }
        const dataToUpdate = {
            info: updatedInfo,
            rows,
            shipping_id: id
        };
        dataToUpdate.info.updated_by = localStorage.getItem("userId");

        try {
            updateShippingReq(dataToUpdate).then(res => {
                if (res.status === 1) {
                    successSnackbar(res.message);
                } else {
                    amberSnackbar(res.message);
                }
            }).catch(error => {
                console.error('API Error:', error);
            });
        } catch (error) {
            alert('Submission Error:', error);
        }
    }

    const pdfToBlob = (requestId) => {
        const doc = new jsPDF('p', 'mm', 'a4'); // A4 size PDF

        // Header with blue background
        doc.setFillColor(22, 160, 133);
        doc.rect(10, 10, 190, 15, 'F');
        doc.setTextColor(255, 255, 255);
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Shipping Request Form", 105, 20, { align: 'center' });

        // Position after header
        let currentY = 30;

        // Pickup and Delivery Information Table
        doc.autoTable({
            startY: currentY,
            theme: 'plain',
            margin: { left: 10, right: 10 },
            styles: { fontSize: 10, cellPadding: 1.5 },
            columnStyles: {
                0: { cellWidth: 45, fontStyle: 'bold', textColor: [22, 160, 133] },
                1: { cellWidth: 45 },
                2: { cellWidth: 45, fontStyle: 'bold', textColor: [22, 160, 133] },
                3: { cellWidth: 45 }
            },
            body: [
                [
                    { content: 'Pickup Information', colSpan: 2, styles: { fillColor: [22, 160, 133], textColor: 255, halign: 'center', fontStyle: 'bold' } },
                    { content: 'Delivery Information', colSpan: 2, styles: { fillColor: [22, 160, 133], textColor: 255, halign: 'center', fontStyle: 'bold' } }
                ],
                ['Address', info.p_address || '', 'Address', info.d_address || ''],
                ['Ready for Pickup Date', info.p_date || '', 'Delivery Date', info.d_date || ''],
                ['Contact Name', info.p_name || '', 'Contact Name', info.d_name || ''],
                ['Email', info.p_email || '', 'Email', info.d_email || ''],
                ['Telephone', info.p_telephone || '', 'Telephone', info.d_telephone || ''],
                ['Comments', info.comments || '']
            ]
        });

        // Update Y position for the items table
        currentY = doc.lastAutoTable.finalY + 10;

        // Item Table
        const itemData = rows.map(row => [
            row.clientName || '',
            row.poto || '',
            row.sku || '',
            row.skuDesc || '',
            row.units || '',
            row.cartons || '',
            row.pallets || '',
            row.dimperpallet || '',
            row.dangerous_goods?.name || '',
            row.stackable?.name || '',
            row.temperature_control?.name || '',
            row.weight || ''
        ]);

        doc.autoTable({
            startY: currentY,
            head: [[
                'Client Name', 'PO / TO #', 'SKU #', 'SKU Description', 'Units',
                'Case/Cartons', 'Pallets', 'Dimensions Per Pallet', 'Dangerous Goods',
                'Stackable', 'Temperature Control', 'Total Weight (kg)'
            ]],
            body: itemData,
            theme: 'grid',
            margin: { left: 10, right: 10 },
            tableWidth: 190,  // Ensures table fits within page width
            styles: { fontSize: 9, cellPadding: 1.2, minCellHeight: 5 },
            headStyles: { fillColor: [22, 160, 133], textColor: 255, fontStyle: 'bold' },
            columnStyles: {
                0: { cellWidth: 20 },  // Client Name
                1: { cellWidth: 15 },  // PO / TO #
                2: { cellWidth: 15 },  // SKU #
                3: { cellWidth: 20 },  // SKU Description
                4: { cellWidth: 12 },  // Units
                5: { cellWidth: 15 },  // Case/Cartons
                6: { cellWidth: 15 },  // Pallets
                7: { cellWidth: 22 },  // Dimensions Per Pallet
                8: { cellWidth: 20 },  // Dangerous Goods
                9: { cellWidth: 15 },  // Stackable
                10: { cellWidth: 20 }, // Temperature Control
                11: { cellWidth: 18 }   // Total Weight
            }
        });

        // Save the PDF as a Blob
        const pdfBlob = doc.output('blob');

        // Convert the Blob to a FormData object for API submission
        const formData = new FormData();
        formData.append('req_id', requestId);
        formData.append("pdf_attachment", pdfBlob, "shipping-request.pdf");

        // Send the email with the attached PDF
        sendmail(formData).then(result => {
            if (result.status === 1) {
                successSnackbar(result.message);
            } else {
                amberSnackbar(result.message);
            }
        });
    };


    const downloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4'); // A4 size PDF

        // Header with blue background
        doc.setFillColor(22, 160, 133);
        doc.rect(10, 10, 190, 15, 'F');
        doc.setTextColor(255, 255, 255);
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Shipping Request Form", 105, 20, { align: 'center' });

        // Position after header
        let currentY = 30;

        // Pickup and Delivery Information Table
        doc.autoTable({
            startY: currentY,
            theme: 'plain',
            margin: { left: 10, right: 10 },
            styles: { fontSize: 10, cellPadding: 1.5 },
            columnStyles: {
                0: { cellWidth: 45, fontStyle: 'bold', textColor: [22, 160, 133] },
                1: { cellWidth: 45 },
                2: { cellWidth: 45, fontStyle: 'bold', textColor: [22, 160, 133] },
                3: { cellWidth: 45 }
            },
            body: [
                [
                    { content: 'Pickup Information', colSpan: 2, styles: { fillColor: [22, 160, 133], textColor: 255, halign: 'center', fontStyle: 'bold' } },
                    { content: 'Delivery Information', colSpan: 2, styles: { fillColor: [22, 160, 133], textColor: 255, halign: 'center', fontStyle: 'bold' } }
                ],
                ['Address', info.p_address || '', 'Address', info.d_address || ''],
                ['Ready for Pickup Date', info.p_date || '', 'Delivery Date', info.d_date || ''],
                ['Contact Name', info.p_name || '', 'Contact Name', info.d_name || ''],
                ['Email', info.p_email || '', 'Email', info.d_email || ''],
                ['Telephone', info.p_telephone || '', 'Telephone', info.d_telephone || ''],
                ['Comments', info.comments || '']
            ]
        });

        // Update Y position for the items table
        currentY = doc.lastAutoTable.finalY + 10;

        // Item Table
        const itemData = rows.map(row => [
            row.clientName || '',
            row.poto || '',
            row.sku || '',
            row.skuDesc || '',
            row.units || '',
            row.cartons || '',
            row.pallets || '',
            row.dimperpallet || '',
            row.dangerous_goods?.name || '',
            row.stackable?.name || '',
            row.temperature_control?.name || '',
            row.weight || ''
        ]);

        doc.autoTable({
            startY: currentY,
            head: [[
                'Client Name', 'PO / TO #', 'SKU #', 'SKU Description', 'Units',
                'Case/Cartons', 'Pallets', 'Dimensions Per Pallet', 'Dangerous Goods',
                'Stackable', 'Temperature Control', 'Total Weight (kg)'
            ]],
            body: itemData,
            theme: 'grid',
            margin: { left: 10, right: 10 },
            tableWidth: 190,  // Ensures table fits within page width
            styles: { fontSize: 9, cellPadding: 1.2, minCellHeight: 5 },
            headStyles: { fillColor: [22, 160, 133], textColor: 255, fontStyle: 'bold' },
            columnStyles: {
                0: { cellWidth: 20 },  // Client Name
                1: { cellWidth: 15 },  // PO / TO #
                2: { cellWidth: 15 },  // SKU #
                3: { cellWidth: 20 },  // SKU Description
                4: { cellWidth: 12 },  // Units
                5: { cellWidth: 15 },  // Case/Cartons
                6: { cellWidth: 15 },  // Pallets
                7: { cellWidth: 22 },  // Dimensions Per Pallet
                8: { cellWidth: 20 },  // Dangerous Goods
                9: { cellWidth: 15 },  // Stackable
                10: { cellWidth: 20 }, // Temperature Control
                11: { cellWidth: 18 }   // Total Weight
            }
        });

        // Save the PDF
        doc.save('shipping-request.pdf');
    };

    return (
        <div style={{ padding: 20 }}>
            <Paper elevation={3} style={{ padding: 20 }}>

                {/* Centered Heading with Blue Background */}
                <h2 style={{
                    textAlign: 'center',
                    backgroundColor: '#1381B9',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '4px'
                }}>
                    Shipping Request Form
                </h2>

                {/* Pickup Address and Delivery Address with Vertical Divider */}
                <Grid container spacing={2} alignItems="center">
                    {/* Pickup Address Section */}
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={
                                <>
                                    Person's Name <span style={{ color: 'red' }}>*</span>
                                </>
                            }
                            name="r_name"
                            value={info.r_name}
                            onChange={handleChange}
                            error={!!errors.r_name}
                            helperText={errors.r_name}
                            InputProps={{
                                readOnly: !!id, // Make the field readonly if id is not null or undefined
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={
                                <>
                                    Person's Phone <span style={{ color: 'red' }}>*</span>
                                </>
                            }
                            name="r_phone"
                            value={info.r_phone}
                            onChange={handleChange}
                            error={!!errors.r_phone}
                            helperText={errors.r_phone}
                            InputProps={{
                                readOnly: !!id, // Make the field readonly if id is not null or undefined
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={
                                <>
                                    Person's Email <span style={{ color: 'red' }}>*</span>
                                </>
                            }
                            name="r_email"
                            value={info.r_email}
                            onChange={handleChange}
                            error={!!errors.r_email}
                            helperText={errors.r_email}
                            InputProps={{
                                readOnly: !!id, // Make the field readonly if id is not null or undefined
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={
                                <>
                                    Person's Company Name <span style={{ color: 'red' }}>*</span>
                                </>
                            }
                            name="r_company_name"
                            value={info.r_company_name}
                            onChange={handleChange}
                            error={!!errors.r_company_name}
                            helperText={errors.r_company_name}
                            InputProps={{
                                readOnly: !!id, // Make the field readonly if id is not null or undefined
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <h3 style={{
                        backgroundColor: '#1381B9',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '4px',
                        marginTop: '20px'
                    }}>
                        Pickup Information
                    </h3>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Pickup Address"
                            name="p_address"
                            value={info.p_address}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            selected={info.p_date ? new Date(info.p_date) : null} // Convert string to Date object
                            onChange={handlePickupDateChange}
                            customInput={<DateCustomInput />}
                            dateFormat="yyyy-MM-dd"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Pickup Name"
                            name="p_name"
                            value={info.p_name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Pickup Email"
                            name="p_email"
                            type="email"
                            value={info.p_email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Pickup Telephone"
                            name="p_telephone"
                            value={info.p_telephone}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Mode of Transport"
                            name="mode_of_transport"
                            value={info.mode_of_transport}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <h3 style={{
                        backgroundColor: '#1381B9',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '4px',
                        marginTop: '20px'
                    }}>
                        Delivery Information
                    </h3>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Delivery Address"
                            name="d_address"
                            value={info.d_address}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Delivery Name"
                            name="d_name"
                            value={info.d_name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Delivery Email"
                            name="d_email"
                            type="email"
                            value={info.d_email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Delivery Telephone"
                            name="d_telephone"
                            value={info.d_telephone}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <h3 style={{
                        backgroundColor: '#1381B9',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '4px',
                        marginTop: '20px'
                    }}>
                        Shipping Items
                    </h3>
                </Grid>
                {/* Dynamic Table for Client Information */}
                <Table style={{ marginTop: 20 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Client Name</TableCell>
                            <TableCell>PO/TO</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell>SKU Description</TableCell>
                            <TableCell>Units</TableCell>
                            <TableCell>Cartons</TableCell>
                            <TableCell>Pallets</TableCell>
                            <TableCell>Dim per Pallet</TableCell>
                            <TableCell>Dangerous Goods</TableCell>
                            <TableCell>Stackable</TableCell>
                            <TableCell>Temperature Control</TableCell>
                            <TableCell>Total Weight (kg)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        name="clientName"
                                        value={row.clientName}
                                        onChange={(e) => handleRowChange(index, e)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        name="poto"
                                        value={row.poto}
                                        onChange={(e) => handleRowChange(index, e)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        name="sku"
                                        value={row.sku}
                                        onChange={(e) => handleRowChange(index, e)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        name="skuDesc"
                                        value={row.skuDesc}
                                        onChange={(e) => handleRowChange(index, e)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        name="units"
                                        value={row.units}
                                        onChange={(e) => handleRowChange(index, e)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        name="cartons"
                                        value={row.cartons}
                                        onChange={(e) => handleRowChange(index, e)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        name="pallets"
                                        value={row.pallets}
                                        onChange={(e) => handleRowChange(index, e)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        name="dimperpallet"
                                        value={row.dimperpallet}
                                        onChange={(e) => handleRowChange(index, e)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        options={dangerousGoods}
                                        getOptionLabel={(option) => option.name}
                                        value={row.dangerous_goods || null}
                                        onChange={(e, newValue) => handleRowChange(index, { target: { name: 'dangerous_goods', value: newValue } })}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                placeholder="Dangerous Goods"
                                                margin="normal"
                                            />
                                        )}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        options={stackable}
                                        getOptionLabel={(option) => option.name}
                                        value={row.stackable || null}
                                        onChange={(e, newValue) => handleRowChange(index, { target: { name: 'stackable', value: newValue } })}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                placeholder="Stackable"
                                                margin="normal"
                                            />
                                        )}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Autocomplete
                                        options={temperaturecontrol}
                                        getOptionLabel={(option) => option.name}
                                        value={row.temperature_control || null}
                                        onChange={(e, newValue) => handleRowChange(index, { target: { name: 'temperature_control', value: newValue } })}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                placeholder="Temperature Control"
                                                margin="normal"
                                            />
                                        )}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        name="weight"
                                        type="number"
                                        value={row.weight}
                                        onChange={(e) => handleRowChange(index, e)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        {/* Total Weight Row */}
                        <TableRow>
                            <TableCell colSpan={9} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                Total Weight:
                            </TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>
                                {totalWeight.toFixed(2)} kg
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={addRow}>
                            Add Row
                        </Button>
                    </Grid>
                </Grid>

                <div style={{ marginTop: 50 }}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Comments"
                            name="comments"
                            value={info.comments || ''}
                            onChange={handleChange}
                            placeholder="Add any additional notes or comments here"
                            multiline
                            InputProps={{
                                readOnly: !!id, // Make the field readonly if id is not null or undefined
                            }}
                            rows={6}
                            variant="outlined"
                        />
                    </Grid>
                </div>

                {/* Button to Add a New Row */}
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                    {id ? (
                        <>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={handleUpdate}>
                                    Update
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginLeft: 10, marginBottom: 20 }}
                                    onClick={downloadPDF}
                                >
                                    Download Form
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    )}
                </Grid>
                {/* Notes Section */}
                <div style={{ marginTop: 30 }}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Notes:
                    </Typography>
                    <Typography variant="body1" component="p" style={{ marginTop: 10, color: 'red', fontWeight: 'bold', fontSize: '0.9rem' }}>
                        ** Please note, a maximum 40ft container fits 24 Euro Pallets or 21 Standard Pallets.
                    </Typography>
                    <Typography variant="body1" component="p" style={{ color: 'red', fontWeight: 'bold', fontSize: '0.9rem' }}>
                        ** Please note, a maximum 20ft container can fit 11 Euro Pallets or 10 Standard Pallets.
                    </Typography>
                    <Typography variant="body1" component="p" style={{ color: 'red', fontWeight: 'bold', fontSize: '0.9rem' }}>
                        ** Multiple Delivery Addresses will require multiple Ship Request Forms.
                    </Typography>
                    <Typography variant="body1" component="p" style={{ color: 'red', fontWeight: 'bold', fontSize: '0.9rem' }}>
                        ** Multiple Pickup Address will require multiple Ship Request Forms
                    </Typography>
                    <Typography variant="body1" component="p" style={{ color: 'red', fontWeight: 'bold', fontSize: '0.9rem' }}>
                        ** Please note, the maximum to a single truck is 26 pallets.
                    </Typography>
                    <Typography variant="body1" component="p" style={{ color: 'red', fontWeight: 'bold', fontSize: '0.9rem' }}>
                        ** Pallet counts above 26 pallets will require additional Ship Request Forms.
                    </Typography>
                    <Typography variant="body1" component="p" style={{ color: 'red', fontWeight: 'bold', fontSize: '0.9rem' }}>
                        ** Packing slips must match what is on a single truck.
                    </Typography>
                    <Typography variant="body1" component="p" style={{ color: 'red', fontWeight: 'bold', fontSize: '0.9rem' }}>
                        ** Please note, the upper limit for a pallet's height is 62” (if shipping by Air) and 88” (if shipping by Sea), and each pallet's weight should not exceed the allowed limit.
                    </Typography>
                    <Typography variant="body1" component="p" style={{ color: 'red', fontWeight: 'bold', fontSize: '0.9rem' }}>
                        ** Shipping requests emailed/submitted after 12:30pm are subject to review/scheduling next business day.
                    </Typography>
                    <Typography variant="body1" component="p" style={{ color: 'red', fontWeight: 'bold', fontSize: '0.9rem' }}>
                        ** Requests submitted after 5:00 PM PST on Fridays are subject to be scheduled the following Monday.
                    </Typography>
                </div>
            </Paper>
        </div >
    );
}

export default ShippingRequest;
