/** @format */

import scanIcon from '../../../../assets/scan.png';
import Edit from '../../../../assets/EditIcon.svg';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback, errorHandler } from '../../../../Common/Fallback';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import { Tooltip } from '@material-ui/core';
import AddCustomerMasterModal from '../../../components/AddCustomerMasterModal';
import { fecthBlacklistCustomer, updateCustomer } from '../../../../Common/API';
export const RenderActionButtons = ({ deletePOSummary, record, summaryList, countries, amberSnackbar, successSnackbar, customerSummary, addPartyScan }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [country, setCountry] = useState([]);

  const addCustomer = (reqData) => {
    updateCustomer(reqData).then(res => {
      if (res.status === 1) {
        successSnackbar(res.message)
        setOpenCustomerModal(false);
        customerSummary();
      } else {
        amberSnackbar(res.message);
        setOpenCustomerModal(true);
      }
    }).catch(error => {
      amberSnackbar(error);
    })
  }
  /**
   * modal close data handling
   */
  const handleModalClose = (confirmState) => {
    deletePOSummary(confirmState, record.pid);
    setOpenDeleteModal(false);
  };

  const handleOpenCustomerModal = (pid) => {
    const ctry = summaryList.find(x => x.pid === pid);
    setCountry(countries.find(x => x.ctrycode === ctry.party_country));
    setSelectedCustomer(summaryList.find(x => x.pid === pid));
    setOpenCustomerModal(!openCustomerModal);
  }
  /**
   * confirm delete table row
   */
  const handleScanCustomer = (pid) => {
    const payload = {
      "search": {
        "securekey": process.env.REACT_APP_ENV_URL,
        "filters": {
          "fuzzylogic": "false",
          "dplguruforensic": "false",
          "sources": "DPLGuru, DPLGuru Demo Portal, JFW, EU Sanctions List",
          "exclusionwords": "pvt",
          "redflag": "military"
        },
        "itemstosearch": [
          {
            "name": record.party_name,
            "address": record.party_addr1 + ' ' + record.party_addr2,
            "country_code": record.party_country
          }
        ]
      }
    }
    fecthBlacklistCustomer(payload).then(data => {
      const req = { "party_id": pid, 'type': 'api', 'matches': data.summary.matches, 'remarks': '', 'user': localStorage.getItem('userId'), statuscode: data.status.statuscode }
      addPartyScan(req);
    }).catch(error => {
      amberSnackbar(error);
    })
  };

  const renderActionButton = () => {
    return (
      <>
        <Tooltip
          title='Edit'
          arrow>
          <img
            src={Edit}
            alt='edit'
            onClick={() => handleOpenCustomerModal(record.pid)}
            style={{ cursor: 'pointer', paddingRight: '10px' }}
          />
        </Tooltip>
        <Tooltip
          title='Scan'
          arrow>
          <img
            src={scanIcon}
            alt='delete'
            onClick={() => handleScanCustomer(record.pid)}
            style={{ cursor: 'pointer' }}
            width='13px'
          />
        </Tooltip>
      </>
    );
  };
  return (
    <>
      {renderActionButton()}
      <ErrorBoundary
        FallbackComponent={Fallback}
        onError={errorHandler}>
        {openDeleteModal && (
          <DeleteConfirmationModal
            handleModalClose={handleModalClose}
            openDeleteModal={openDeleteModal}
          />
        )}
      </ErrorBoundary>
      <ErrorBoundary
        FallbackComponent={Fallback}
        onError={errorHandler}>
        {openCustomerModal && (
          <AddCustomerMasterModal
            openCustomerModal={openCustomerModal}
            setOpenCustomerModal={setOpenCustomerModal}
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
            country={country}
            setCountry={setCountry}
            countries={countries}
            addCustomer={addCustomer}
            pid={record.pid}
          />
        )}
      </ErrorBoundary>
    </>
  );
};
