import { Grid, Typography, Paper, makeStyles } from "@material-ui/core";
import { ErrorBoundary } from "react-error-boundary";
import { Fallback, errorHandler } from "../Common/Fallback";
import React from "react";
import FeaturePermission from "./FeaturePermission";

const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid #E0E0E0',
    },
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`,
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    }
}))

const UsersFeature = ({setTitle}) => {
    setTitle('User Features');
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid container>
                <Grid container className={`${classes.header} ${classes.sidePadding}`}>
                    <Grid item xs={4} className="d-flex align-center">
                        <Typography variant="h6" className={classes.title}>User Feature</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid style={{ padding: '0 31px' }}>
                <Paper component="form" className={classes.paper}>
                    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
                        <FeaturePermission />
                    </ErrorBoundary>
                </Paper>
            </Grid>
        </React.Fragment>
    );
}

export default UsersFeature;