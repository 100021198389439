import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import './AdminDashboard.styles.css';
import customerIcon from '../assets/customer-icon.png';
import vaultIcon from '../assets/vault.png';
import { Grid, ButtonGroup, Button } from '@material-ui/core';
import searchIcon from '../assets/searchIcon.png';
import trackIcon from '../assets/logistics.png';
import permissionIcon from '../assets/permissionIcon.png';
import shippingrequest from '../assets/shiprequest.png';

const AdminDashboard = ({ setTitle }) => {
    setTitle('Utility Activities');
    const navigate = useNavigate();

    const handleScreenChange = (newScreen) => {
        if (newScreen !== null) {
            if (newScreen === 'Client') navigate('/manage-orders');
        }
    }
    const renderCards = () => {
        const cards = [
            {
                title: 'Manage Customers',
                description: 'Add, edit and view existing customers from here.',
                disabled: false,
                route: `/customer`
            },
            {
                title: 'Track Shipment',
                description: 'Track the shipments from here.',
                disabled: false,
                route: `/track-shipment`
            },
            {
                title: 'File Vault',
                description: 'Store any type of file from here.',
                disabled: false,
                route: `/file-vault`
            },
            {
                title: 'Search Customer',
                description: 'Check for number of matches and blacklisted customer from here.',
                disabled: false,
                route: `/search-customer`
            },
            // {
            //     title: 'User Feature',
            //     description: 'Check for necessary access to features for specific customer.',
            //     disabled: false,
            //     route: `/user-feature`
            // },
            {
                title: 'Review Shipping Requests',
                description: 'Review shipping requests made by customers.',
                disabled: false,
                route: `/shipping-request-list`
            }
        ];

        return cards.map((activity, index) => {
            let icon;
            switch (index) {
                case 0:
                    icon = <img src={customerIcon} alt='customer' className="card-icon" />
                    break;
                case 1:
                    icon = <img src={trackIcon} alt='trackShipment' className="card-icon" />
                    break;
                case 2:
                    icon = <img src={vaultIcon} alt='vault' className="card-icon" />
                    break;
                case 3:
                    icon = <img src={searchIcon} alt='partysearch' className="card-icon" />
                    break;
                case 4:
                    icon = <img src={permissionIcon} alt='userpermission' className="card-icon" />
                    break;
                case 5:
                    icon = <img src={shippingrequest} alt='shippingrequest' className="card-icon" />
                    break;
                default:
                    icon = <img src={vaultIcon} alt='vault' className="card-icon" />
                    break;
            }

            const handleClick = title => {
                if (title === 'Purchase Order') {
                    Cookies.set('title', title);
                }
            }

            return (
                <div className='card-content' data-testid={`card-${index}`} key={`card-${index}`}>
                    <Link to={activity.route} onClick={e => handleClick(activity.title)}>
                        <div className='card-header'>{activity.title}</div>
                        <div className='card-body'>
                            {icon}
                            <div className='card-description'>{activity.description}</div>
                        </div>
                    </Link>
                </div>
            );
        });
    };

    return (
        <div className="container">
            <Grid item container xs={12} className="adminDashHeadingWrap">
                <ButtonGroup className="roundedBtn" size="large" variant="outlined">
                    <Button color="primary" variant="contained" onClick={() => handleScreenChange('Utility')}>Utility Activities</Button>
                    <Button color="primary" onClick={() => handleScreenChange('Client')}>Manage Orders</Button>
                </ButtonGroup>
            </Grid>
            <div className="card-container">{renderCards()}</div>
        </div>
    )
}

export default AdminDashboard;