/** @format */
import Edit from '../../../../../assets/EditIcon.svg';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
export const RenderActionButtons = ({ record }) => {
  const party_type = localStorage.getItem('party_type');

  return (
    <>
      <Link to={party_type !== "CST" && party_type !== "SLS" ? `/edit-work-order/${record.workorder}` : `/customer-work-order/${record.workorder}`}>
        <Tooltip
          title='Edit'
          arrow>
          <img
            src={Edit}
            alt='edit'
            style={{ cursor: 'pointer', paddingRight: '10px' }}
          />
        </Tooltip>
      </Link>
    </>
  );
};
