/** @format */

import React from 'react';
import { cloneDeep } from 'lodash';
import { MainContext, contextDefault } from './Context/main-context';
import './css/common.css';
import { UserAuthContextProvider } from "./Auth/context/UserAuthContext";
import { Route } from 'react-router-dom';
import Login from './Auth/Login';
import PurchaseOrder from './PurchaseOrder';
import { POSummaryContextProvider } from './PurchaseOrder/components/context/POSummaryContext';
import CreatePO from './PurchaseOrder/components/CreatePO';
import SnackbarProvider from 'react-simple-snackbar'
import { useState } from 'react';
import AdminDashboard from './AdminDashboard';
import ClientDashboard from './ClientDashboard';
import WorkOrder from './WorkOrder';
import { WOSummaryContextProvider } from "./WorkOrder/components/context/WOSummaryContext";
import Shipment from './Shipment';
import { ShipmentSummaryContextProvider } from './Shipment/components/context/ShipmentSummaryContext';
import CreateShipment from './Shipment/components/CreateShipment';
import FileVault from './FileVault';
import Customer from './Customer';
import { CustomerSummaryContextProvider } from './Customer/context/CustomerSummaryContext';
import CustomerAddress from './CustomerAddress';
import CustomerDashboard from './CustomerDashboard';
import { ReportProvider } from './CustomerDashboard/context';
import EditWorkOrder from './WorkOrder/components/EditWorkOrder';
import PartyScan from './PartyScan';
import { PartyScanSummaryContextProvider } from './PartyScan/components/context/PartyScanSummaryContext';
import BulkUpload from './BulkUpload';
import ContactDetails from './ContactDetails';
import OtherUserLogin from './Auth/OtherUserLogin';
import CustomerWorkOrder from './WorkOrder/components/CustomerWorkOrder';
import SearchParty from './SearchParty';
import { SearchPartyContextProvider } from './SearchParty/components/Context';
import SeacrhHistory from './SearchHistory';
import { SearchHistoryContextProvider } from './SearchHistory/components/Context';
import UserManagement from './UserManagement';
import Layout from './Common/Layout';
import TrackShipment from './TrackShipment';
import SalesManagement from './SalesManagement';
import SalesDashboard from './SalesDashboard';
import { SalesReportProvider } from './SalesDashboard/context';
import UsersFeature from './UsersFeature';
import ShippingRequest from './ShippingRequest';
import ReviewRequest from './ShippingRequest/ReviewRequest';

function App() {
  // TODO: research whether we are actually using sentry or not
  //Sentry.init({ dsn: 'https://54c338f32527403a8648f6b75e5ab5cd@o395506.ingest.sentry.io/5247413' });

  const [context, setContext] = useState(cloneDeep(contextDefault));
  const [title, setTitle] = useState("");

  return (
    <MainContext.Provider value={[context, setContext]}>
      <UserAuthContextProvider>
        <SnackbarProvider>
          <Layout title={title}>
            <Route exact path="/manage-orders" element={<ClientDashboard setTitle={setTitle} />} />
            <Route path="/other-user-login" element={
              <OtherUserLogin />
            } />
            <Route path="/purchase-order/summary" element={
              <POSummaryContextProvider>
                <PurchaseOrder setTitle={setTitle} />
              </POSummaryContextProvider>} />
            <Route path="/create-purchase-order" element={<CreatePO setTitle={setTitle} />} />
            <Route path="/utility-activities" element={<AdminDashboard setTitle={setTitle} />} />
            <Route path="/edit-purchase-order/:poid" element={<CreatePO setTitle={setTitle} />} />
            <Route path="/work-order/summary" element={
              <WOSummaryContextProvider>
                <WorkOrder setTitle={setTitle} />
              </WOSummaryContextProvider>
            } />
            <Route path="/edit-work-order/:woid" element={<EditWorkOrder setTitle={setTitle} />} />
            <Route path="/shipment/Summary" element={
              <ShipmentSummaryContextProvider>
                <Shipment setTitle={setTitle} />
              </ShipmentSummaryContextProvider>
            } />
            <Route path="/create-shipment" element={<CreateShipment setTitle={setTitle} />} />
            <Route path="/edit-shipment/:invid" element={<CreateShipment setTitle={setTitle} />} />
            <Route path="/file-vault" element={<FileVault setTitle={setTitle} />} />
            <Route path="/customer" element={
              <CustomerSummaryContextProvider>
                <Customer setTitle={setTitle} />
              </CustomerSummaryContextProvider>
            } />
            <Route path="/customer-address/:id" element={<CustomerAddress setTitle={setTitle} />} />
            <Route path="/customer-dashboard" element={
              <ReportProvider>
                <CustomerDashboard setTitle={setTitle} />
              </ReportProvider>
            } />
            <Route path="/sales-dashboard" element={
              <SalesReportProvider>
                <SalesDashboard setTitle={setTitle} />
              </SalesReportProvider>
            } />
            <Route path="/customer-work-order/:custwoid" element={<CustomerWorkOrder setTitle={setTitle} />} />
            <Route path="/party-scan" element={
              <PartyScanSummaryContextProvider>
                <PartyScan setTitle={setTitle} />
              </PartyScanSummaryContextProvider>
            } />
            <Route path="/upload-data" element={<BulkUpload setTitle={setTitle} />} />
            <Route path="/contact-details/:id" element={<ContactDetails setTitle={setTitle} />} />
            <Route path="/search-customer" element={
              <SearchPartyContextProvider>
                <SearchParty setTitle={setTitle} />
              </SearchPartyContextProvider>
            } />
            <Route path="/search-history" element={
              <SearchHistoryContextProvider>
                <SeacrhHistory setTitle={setTitle} />
              </SearchHistoryContextProvider>
            } />
            <Route path="/manage-users" element={
              <SnackbarProvider>
                <UserManagement setTitle={setTitle} />
              </SnackbarProvider>
            } />
            <Route path="/manage-sales" element={
              <SnackbarProvider>
                <SalesManagement setTitle={setTitle} />
              </SnackbarProvider>
            } />
            <Route path="/track-shipment" element={
              <TrackShipment setTitle={setTitle} />
            } />
            <Route path="/user-feature" element={
              <UsersFeature setTitle={setTitle} />
            } />
            <Route path="/shipping-request" element={
              <ShippingRequest setTitle={setTitle} />
            } />
            <Route path="/review-shipping-req/:id" element={<SnackbarProvider>
              <ShippingRequest setTitle={setTitle} /></SnackbarProvider>} />
            <Route path="/shipping-request-list" element={
              <ReviewRequest setTitle={setTitle} />
            } />
            <Route path='/' element={<Login />} />
          </Layout>
        </SnackbarProvider>
      </UserAuthContextProvider>
    </MainContext.Provider>
  );
}

export default App;