import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import dhlIcon from '../assets/dhl.png';
import fedexIcon from '../assets/fedex.png';
import cargoIcon from '../assets/air-cargo.png';
import containerIcon from '../assets/containers.png';
import React from "react";

const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: '1px solid #E0E0E0',
    },
    sidePadding: {
        padding: `0 ${theme.spacing(3)}px`
    },
    title: {
        fontSize: theme.spacing(4) - 4,
        color: theme.palette.primary.dark,
        padding: `${theme.spacing(1.2)}px 0`,
    },
    paper: {
        background: 'none',
        width: '100%',
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(2),
        boxShadow: 'none'
    },

    inputFieldContainer: {
        position: 'relative',
        width: '545px', // Adjust the width as needed
        margin: 'auto', // Center the container horizontally
        marginBottom: theme.spacing(2),
    },

    inputField: {
        width: '100%',
        padding: '21.5px', // Adjust padding as needed
        boxSizing: 'border-box',
        border: '1px solid #3e8ed0', // Blue border color
        borderRadius: '4px', // Rounded corners
        margin: 'auto',
        marginBottom: theme.spacing(2),
    },

    image: {
        marginLeft: '325px',
        marginTop: '-15px',
        position: 'absolute',
        width: "5%", // Adjust the image width as needed
        height: "83px", // Adjust the image height as needed
    },

    submitButton: {
        position: 'absolute',
        right: 0,
        top: '39%',
        transform: 'translateY(-50%)',
        padding: '21px 30px', // Adjust padding as needed
        background: '#3e8ed0', // Blue background color
        color: '#fff', // White text color
        borderTopRightRadius: '4px', // Rounded top-right corner
        borderBottomRightRadius: '4px', // Rounded bottom-right corner
        border: 'none',
        cursor: 'pointer',
        fontSize: 'medium',
    },

    nologo: {
        fontSize: '200%',
        fontWeight: 'bold',
        color: 'black',
        height: '68px',
        marginLeft: '339px',
        marginTop: '9px',
        position: 'absolute',
    }
}))

const TrackShipment = ({ setTitle }) => {
    setTitle('Track Shipment');
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid container>
                <Grid container className={`${classes.header} ${classes.sidePadding}`}>
                    <Grid item xs={4} className="d-flex align-center">
                        <Typography variant="h6" className={classes.title}>Track Shipment</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid style={{ padding: '0 31px' }}>
                <Paper component="form" className={classes.paper}>
                    <Grid container>
                        <Grid item xs={12}>
                            <img src={dhlIcon} alt="dhl" className={classes.image} />
                            <div className={classes.inputFieldContainer}>
                                <form action="https://connect.track-trace.com/for/sasa" method="post" target="_blank">
                                    <input type="hidden" name="shipper" value="dhl" />
                                    <input type="text" name="number" placeholder="DHL" className={classes.inputField} />
                                    <input type="submit" value="Track" className={classes.submitButton} />
                                </form>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.nologo}>UPS</div>
                            <div className={classes.inputFieldContainer}>
                                <form action="https://connect.track-trace.com/for/sasa" method="post" target="_blank">
                                    <input type="hidden" name="shipper" value="ups" />
                                    <input type="text" name="number" placeholder="UPS" className={classes.inputField} />
                                    <input type="submit" value="Track" className={classes.submitButton} />
                                </form>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <img src={fedexIcon} alt="fedex" className={classes.image} />
                            <div className={classes.inputFieldContainer}>
                                <form action="https://connect.track-trace.com/for/sasa" method="post" target="_blank">
                                    <input type="hidden" name="shipper" value="fedex" />
                                    <input type="text" name="number" placeholder="Fedex" className={classes.inputField} />
                                    <input type="submit" value="Track" className={classes.submitButton} />
                                </form>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <img src={cargoIcon} alt="cargo" className={classes.image} />
                            <div className={classes.inputFieldContainer}>
                                <form action="https://connect.track-trace.com/for/sasa" method="post" target="_blank">
                                    <input type="hidden" name="shipper" value="aircargo" />
                                    <input type="text" name="number" placeholder="Air Cargo" className={classes.inputField} />
                                    <input type="submit" value="Track" className={classes.submitButton} />
                                </form>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <img src={containerIcon} alt="container" className={classes.image} />
                            <div className={classes.inputFieldContainer}>
                                <form action="https://connect.track-trace.com/for/sasa" method="post" target="_blank">
                                    <input type="hidden" name="shipper" value="container" />
                                    <input type="text" name="number" placeholder="Container" className={classes.inputField} />
                                    <input type="submit" value="Track" className={classes.submitButton} />
                                </form>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.nologo}>B/L</div>
                            <div className={classes.inputFieldContainer}>
                                <form action="https://connect.track-trace.com/for/sasa" method="post" target="_blank">
                                    <input type="hidden" name="shipper" value="billoflading" />
                                    <input type="text" name="number" placeholder="Bill of Lading" className={classes.inputField} />
                                    <input type="submit" value="Track" className={classes.submitButton} />
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}

export default TrackShipment;